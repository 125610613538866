<template>
  <span :class="$style.count">Количество: {{ count }}</span>
</template>
<script>
export default {
  props: {
    count: {
      type: Number,
      default: 0
    }
  }
}
</script>
<style lang="scss" module>
.count {
  @include H200;
  color: $black;
}
</style>
