<template>
  <div :class="$style.page">
    <div :class="$style.headerWrapper">
      <CountAtom :count="count" :class="$style.count" />
      <el-button
        type="primary"
        size="small"
        @click="
          $router.push(getRoute({ route: $options.LANDING_ROUTES.CREATE }))
        "
        >Создать
      </el-button>
    </div>
    <el-table :data="list" @sort-change="onSortChange">
      <el-table-column label="Фото">
        <template slot-scope="scope">
          <img
            :src="getLandingImage(scope.row.favicon)"
            @error="setDefaultImage"
            :class="$style.image"
            alt="no-photo"
          />
        </template>
      </el-table-column>
      <el-table-column
        prop="name"
        label="Название"
        :sort-orders="['asc', 'desc']"
        sortable
      >
      </el-table-column>
      <el-table-column width="150">
        <template slot-scope="scope">
          <ActionButtons
            :class="$style.buttons"
            :edit-link="
              getRoute({
                route: $options.LANDING_ROUTES.VIEW,
                params: { id: scope.row.id },
              })
            "
            @delete="deleteLanding(scope.row)"
            :view-link="getLandingUrl(scope.row.name)"
          />
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="totalPages > 1"
      layout="prev, pager, next"
      :page-size="limit"
      :total="count"
      :current-page.sync="page"
      background
      :class="$style.pagination"
      @current-change="getLandings"
    >
    </el-pagination>
  </div>
</template>

<script>
import delivery from '@/delivery'
import images from '@/mixins/images'

import { LANDING_ROUTES, getRoute } from '@/constants/routing'

import CountAtom from '@/components/atoms/Count.vue'
import ActionButtons from '@/components/atoms/ActionsButtons.vue'

export default {
  LANDING_ROUTES,
  name: 'LandingList',
  mixins: [images],
  components: { CountAtom, ActionButtons },
  data() {
    return {
      list: [],
      count: 0,
      page: 1,
      limit: 20,
      orderBy: 'name',
      orderDir: 'asc',
    }
  },
  async created() {
    await this.getLandings()
  },
  computed: {
    totalPages() {
      return Math.ceil(this.count / this.limit)
    },
  },
  methods: {
    async onSortChange({ prop, order }) {
      if (prop && order) {
        this.orderBy = prop
        this.orderDir = order ?? null
      }
      this.page = 1
      await this.getLandings()
    },
    getLandingUrl(landingName) {
      return this.$configData.landing_url
        ? this.$configData.landing_url.replace('landingName', landingName)
        : ''
    },
    getLandingImage(landingImage) {
      return this.$configData.s3_link + landingImage
    },
    async getLandings() {
      const query = {
        page: this.page,
        limit: this.limit,
        orderBy: this.orderBy,
        orderDir: this.orderDir,
      }

      const { value, error } =
        await delivery.LandingsCore.LandingActions.getList(query)

      if (error) return

      this.list = value.data
      this.count = value.meta.count
    },
    async deleteLanding(landing) {
      const isConfirm = confirm(
        `Вы точно хотите удалить лендинг: ${landing.name} ?`,
      )

      if (!isConfirm) return

      const { error } =
        await delivery.LandingsCore.LandingActions.deleteLandingById(landing.id)

      if (error) return

      await this.getLandings()
    },
    getRoute({ route, params }) {
      return getRoute({ route, params })
    },
  },
}
</script>

<style lang="scss" module>
.page {
  padding: 1rem;

  .headerWrapper {
    @include stickyWrapper;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .image {
    max-height: 8rem;
    max-width: 8rem;
  }

  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
}
</style>
